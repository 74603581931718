.container-producto {
    margin-top: -50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-items: flex-start;
    padding-bottom: 100px;
}

.producto-detail {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-items: flex-start;
    width: 45%;
    background: #F9F9FA;
    box-shadow: 15.2537px 17.796px 30.5075px 7.62687px rgba(0, 0, 0, 0.12);
    border-radius: 20px;
}

.producto-carrousel {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.producto-imagen {
    padding-top: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-items: flex-start;
}

.producto-imagen img {
    border-radius: 20px;
    max-width: 450px;
    max-height: 450px;
}

.producto-titulo {
    margin-top: -2%;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 90%;
    max-width: 550px;
    background: #E8E8E8;
    box-shadow: 0px -26.5672px 33.209px -4.42786px rgba(0, 0, 0, 0.25);
    border-radius: 20px;
}

.producto-titulo h5 {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    text-align: center;
}

.producto-descripcion {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 80%;
    padding: 50px 0;
}

.producto-descripcion span {
    width: 80%;
}

.arrow-next-producto-detail {
    width: 60px;
    height: 60px;
    background: #FFE81E;
    border-radius: 36px;
    margin-left: -3%;
    margin-bottom: -50px;
    box-shadow: 3px 4px 7px 1px rgba(0, 0, 0, 0.15);
}

.arrow-back-producto-detail {
    visibility: hidden;
    width: 60px;
    height: 60px;
    background: #FFE81E;
    border-radius: 36px;
    margin-right: -3%;
    z-index: 100;
    margin-bottom: -50px;
    box-shadow: 3px 4px 7px 1px rgba(0, 0, 0, 0.15);
}

.arrow-back-icono-producto-detail, .arrow-next-icono-producto-detail {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.container-atras {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    padding-left: 20%;
}

.atras-boton {
    position: absolute;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    background-color: #FFE81E;
    font-size: 25px;
    border-radius: 20px;
    padding: 10px 15px;
    margin-top: 50px;
    box-shadow: 3px 4px 7px 1px rgba(0, 0, 0, 0.15);
}

.atras-boton:hover, .arrow-back-producto-detail:hover, .arrow-next-producto-detail:hover {
    box-shadow: 3px 4px 7px 5px rgba(0, 0, 0, 0.15);
}

.atras-boton img {
    height: 25px;
}

.atras-boton span {
    padding: 0 25px;
}

/*RESPONSIVE*/
@media only screen and (max-width: 1000px) {
    .atras-boton span {
        display: none;
    }
    .producto-imagen img {
        max-width: 250px;
        max-height: 250px;
    }
}

@media only screen and (max-width: 900px) {
    .producto-imagen img {
        max-width: 250px;
        max-height: 250px;
    }
}
@media only screen and (max-width: 600px) {
    .producto-imagen img {
        max-width: 200px;
        max-height: 200px;
    }
    .producto-titulo h5 {
        font-size: 15px;
    }
    .container-producto {
        margin-top: -100px;
    }
    .arrow-next-producto-detail, .arrow-back-producto-detail {
        width: 40px;
        height: 40px;
    }
    .producto-detail {
        width: 70%;
    }
}