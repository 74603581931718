.loader-container {
    display: flex;
    padding-top: 100px;
    margin-bottom: 200px;
    justify-content: center;
    text-align: center;
    align-items: center;
    width: 100%;
}

.loader{
    display: grid;
    position: relative;
    width: 200px;
    height: 200px;
    border-radius: 50%;
    background: linear-gradient(#e9e9e9, #e9e9e9, #FFE925);
    animation: animate 0.5s linear infinite;
}   
@keyframes animate {
    0%{transform: rotate(0deg);} 
    100%{transform: rotate(360deg);} 
}
.loader span{
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background: linear-gradient(#e9e9e9, #e9e9e9, #FFE925);
}
.loader span:nth-child(1){
    filter: blur(5px);
}
.loader span:nth-child(2){
    filter: blur(10px);
}
.loader span:nth-child(3){
    filter: blur(25px);
}
.loader span:nth-child(4){
    filter: blur(10px);
}
.loader::after{
    content: '';
    position: absolute;
    top: 30px;
    left: 30px;
    right: 30px;
    bottom: 30px;
    background: #e9e9e9;
    border-radius: 50%;
}