@keyframes scroll {
	0% {
		transform: translateX(0);
	}
	100% {
		transform: translateX(calc(-250px * 7));
	}
}
.slider {
	box-shadow: 0 10px 20px -5px rgba(0, 0, 0, .125);
	margin: auto;
	overflow: hidden;
	position: relative;
	width: 100%;
}
.slider::before, .slider::after {
	background: linear-gradient(to right, rgba(255, 255, 255, .78) 0%, rgba(255, 255, 255, 0) 100%);
	content: "";
	height: 150px;
	position: absolute;
	width: 200px;
	z-index: 10;
}
.slider::after {
	right: 0;
	top: 0;
	transform: rotateZ(180deg);
}
.slider::before {
	left: 0;
	top: 0;
}
.slider .slide-track {
	animation: scroll 40s linear infinite;
	display: flex;
	width: calc(250px * 14);
}

.slide {
	padding: 0 50px;
}

.slide img {
	width: 200px;
	height: 100px;
}

@media only screen and (max-width: 600px) {
	
}
