.page-container {
    display: flex;
    flex-direction: column;
}

.page-productos-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: -50px;
}

.page-productos-title {
    margin-top: -200px;
    position: absolute;
    height: 100px;
    background: #FFE81E;
    box-shadow: 0px 12px 15px 2px rgba(0, 0, 0, 0.25);
    border-radius: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.background-page-productos {
    width: 90%;
    height: 100%;
}

.page-productos-title h4 {
    font-size: 30px;
    padding: 0 50px;
}

.list-container {
    margin: 0 100px;
}

@media only screen and (max-width: 800px) {
    .background-page-productos {
        width: 100%;
    }
    .page-productos-title h4 {
        font-size: 30px;
    }
    .page-container {
        margin-top: -170px;
    }
    .page-productos-title {
        margin-top: -50px;
        height: 50px;
    }
}

@media only screen and (max-width: 500px) {
    .page-productos-title {
        margin-top: 0px;
    }
    .page-productos-title h4 {
        font-size: 15px;
        padding: 0 10px;
    }
}