.container-productos, .container-producto0, .container-producto1, .container-producto2 {
    margin: 0 50px;
}

.container-productoundefined {
    padding: 25px 10px;
}

.page-products-list-container, .container-producto0, .container-producto1, .container-producto2 {
    margin: 20px;
}

#container-producto {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

#container-producto a {
}

.container-producto-background-1 {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 250px;
    height: 250px;
    background: #F9F9FA;
    box-shadow: 6px 7px 12px 3px #0000001f;
    border-radius: 40px;
    z-index: 10000;
}

.container-producto-background-2 {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 70px;
    width: 100%;
    background: #E8E8E8;
    box-shadow: 0px 12px 15px 2px rgba(0, 0, 0, 0.25);
    border-radius: 40px;
    margin-top: -50px;
    z-index: 10000000;
}
.container-producto-background-2 h5 {
    text-align: center;
}

.container-producto-imagen {
    padding: 10px;
    border-radius: 40px;
    height: 230px;
    width: 230px;
    z-index: 100000;
    object-fit: fill;
}

.container-producto-imagen-degrade {
    margin: 10px 0 0 10px;
    position: absolute;
    background-color: #09070722;
    width: 230px;
    height: 230px;
    border-radius: 40px;
    z-index: 1000000;
}

/*RESPONSIVE*/
@media only screen and (max-width: 1150px) {
    .container-productos, .container-producto0, .container-producto1, .container-producto2  {
        margin: 0 20px;
    }
}

@media only screen and (max-width: 1000px) {
    .container-producto1, .container-producto2  {
        display: none;
    }
    .container-producto0 {
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

@media only screen and (max-width: 600px) {
    .container-producto0 {
        margin: 0 0;
    }
}

@media only screen and (max-width: 450px) {
    .container-producto-background-1 {
        align-items: center;
        width: 190px;
        height: 240px;
    }
    
    .container-producto-background-2 {
        align-items: center;
        width: 190px;
        height: 70px;
        margin-top: -70px;
    }
    
    .container-producto-imagen {
        padding: 10px;
        height: 240px;
        width: 170px;
    }
    
    .container-producto-imagen-degrade {
        margin: 10px 0 0 10px;
        width: 170px;
        height: 220px;
    }
}