/* .background {
    position: absolute;
    height: 1400px;
    width: 100%;
    margin-top: -1%;
    background-attachment: scroll;
    background-repeat: no-repeat;
    background-image: url("../../imagenes/fondo-encabezado.png");
    background-position: center top;
    background-size: 100%;
    z-index: -1000;
} */

.navbar {

}

.container-navigation {
    z-index: 100000000;
    width: 100%;
    height: 250px;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.73) 0%, rgba(255, 255, 255, 0) 72.48%);
    font-size: 1vw;
}

.navigation {
    position: fixed;
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    width: 100%;
    padding-bottom: 0;
    margin-bottom: 0;
    height: 150px;
    z-index: 100000000;
}

.container-logo-marca {
    width: 20%;
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    padding: 0;
    margin: 0;
}

.container-logo-marca img {
    height: 120px;
    width: 178px;
    padding-left: 20%;
}

.container-logo-marca-responsive {
    display: none;
}


.navbar-style {
    display: flex;
    justify-content: space-around;
    align-items: flex-end;
    padding: 0;
    margin: 0;
    gap: 32px;
    list-style: none;
    text-decoration: none;
}

.navbar-style li {
    padding-bottom: 7px;
}

.navbar-style .list h2 {
    padding: 0;
    margin: 0;
    font-weight: lighter;
}

.navbar-style .list-trabaja-con-nosotros h2 {
    padding: 0;
    margin: 0;
    font-weight: bold;
    border: 3px solid #FFE925;
    padding: 10px;
    margin-bottom: -10px;
    border-radius: 5px;
}

.list a {
    color: #181818;
}

.list a:hover {
    color: #FFE925;
}

.list-trabaja-con-nosotros a {
    color: #FFE925;
}

.list-trabaja-con-nosotros a:hover {
    color: #181818;
}

.list-trabaja-con-nosotros h2 {
    list-style: none;
    text-decoration: none;
    border: 3px solid #FFE925;
    padding: 10px;
    border-radius: 5px;
}

.navigation-responsive {
    display: none;
}

#close {
    display: none;
}

/*RESPONSIVE*/
@media only screen and (max-width: 1200px) {
    .navigation {
        margin-top: -30px;
    }
    .container-logo-marca img {
        height: 100px;
        width: 150px;
        padding-left: 20%;
    }
}

@media only screen and (max-width: 1000px) {
    .container-logo-marca img {
        height: 80px;
        width: 120px;
        padding-left: 20%;
        padding-bottom: 5%;
    }
}

@media only screen and (max-width: 800px) {
    .navigation {
        display: none;
        height: 200px;
    }
    .container-navigation {
        width: 100%;
    }
    .navigation-responsive {
        display: flex;
        position: fixed;
        justify-content: center;
        align-items: center;
        width: 100%;
        background-color: #f0f0f0FF;
        z-index: 1000000000000;
    }
    .navbar-responsive-fijo {
        margin-top: 10px;
        display: flex;
        width: 100%;
        height: 50px;
        z-index: 1000000000000;
        background-color: #f0f0f0FF;
    }
    .container-logo-marca-responsive {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        width: 100%;
    }
    .container-logo-marca-responsive img {
        height: 80px;
        margin-left: 10px;
    }
    .container-open {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        width: 100%;
        z-index: 100000000000;
    }
    .open {
        height: 40px;
        width: 40px;
        margin-right: 10px;
    }
    .container-close {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding-right: 5px;
        width: 100%;
        z-index: 100000000000;
    }
    .close {
        height: 40px;
        width: 40px;
        margin-right: 10px;
    }
    ion-icon {
        padding: 5px;
    }
    .navbar-list-responsive {
        padding-top: 300px;
        position: absolute;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: #f0f0f0FF;
        width: 100%;
        margin: 0;
        padding-left: 0;
        z-index: 1000000;
    }
    .list-responsive {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        list-style: none;
        text-decoration: none;
        font-size: 10px;
    }
    .list-responsive:hover {
        background-color: #FFE925;
    }
    .list-text-responsive {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 15px;
    }
}