.container-section-certificados {
    text-align: center;
    align-items: center;
    margin: 80px 0;
}

.container-certificados {

}

.container-certificados-title h4 {
    font-size: 30px;
    font-weight: bold;
}

.container-certificados-title img {
    width: 7%;
    margin-bottom: -30px;
}

.certificados, .logos-certificados {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    width: 80%;
    margin: 0 10%;
}

.certificados-carrousel {
    display: flex;
    width: 100%;
    background: #F9F9F9;
    box-shadow: 20px 17px 21px #00000014;
    border-radius: 50px;
    padding: 20px 0;
}

.componente-carrousel, .logo-certificado, .logo-certificado-iram {
    display: flex;
    width: 35%;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
}

.arrow-next {
    width: 75px;
    height: 60px;
    background: #FFE81E;
    border-radius: 36px;
    margin-left: -5%;
    box-shadow: 3px 4px 7px 1px rgba(0, 0, 0, 0.15);
}

.arrow-back {
    width: 75px;
    height: 60px;
    background: #FFE81E;
    border-radius: 36px;
    margin-right: -5%;
    z-index: 100;
    box-shadow: 3px 4px 7px 1px rgba(0, 0, 0, 0.15);
}

.arrow-back:hover, .arrow-next:hover {
    box-shadow: 3px 4px 7px 5px rgba(0, 0, 0, 0.15);
}

.arrow-back-icono, .arrow-next-icono {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.logo-certificado img {
    margin-top: 50px;
    width: 50%;
}

.logo-certificado-iram img {
    margin-top: 50px;
    width: 25%;
}

.logos-certificados-responsive {
    display: none;
}

@media only screen and (max-width: 1200px) {
    .componente-carrousel, .logo-certificado, .logo-certificado-iram {
        width: 45%;
    }
}

@media only screen and (max-width: 1000px) {
    .certificados {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 60%;
        margin: 0 20%;
    }
    .logos-certificados {
        display: none;
    }
    .logos-certificados-responsive {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        margin-bottom: 50px;
    }
    .componente-carrousel, .logo-certificado, .logo-certificado-iram {
        width: 90%;
    }
    .arrow-next {
        width: 60px;
        height: 45px;
    }
    .arrow-back {
        width: 60px;
        height: 45px;
    }
    .container-certificados-title img {
        width: 10%;
    }
}

@media only screen and (max-width: 600px) {
    .certificados {
        width: 100%;
        margin: 0 0;
    }
    .container-certificados-title img {
        width: 20%;
    }
    .container-certificados-title h4 {
        width: 90%;
        padding: 0 5%;
    }
}